import { createStore } from 'vuex'

export default createStore({
  state: {
    lang : 'fr',
    mode : "dark",
    documentReady : false
  },
  getters: {
  },
  mutations: {
    setLang(state,lang){
      state.lang = lang;
    },
    setScreenMode(state,mode){
      state.mode = mode
    },
    setDocumentReady(state,bool){
      state.documentReady = bool
    }
  },
  actions: {
    setLang({ commit },lang) {
      commit('setLang', lang);
    },
    setScreenMode({ commit },mode) {
      commit('setScreenMode', mode);
    },
    setDocumentReady({ commit },bool) {
      commit('setDocumentReady', bool);
    },
  },
  modules: {
  }
})
