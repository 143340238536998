import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, concat } from '@apollo/client/core'
import { createApolloProvider } from '@vue/apollo-option'

const token = "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE3MTY5OTIwMzUsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEtc2hhcmVkLWV1YzEtMDIuaHlncmFwaC5jb20vdjIvY2xzbHllbnRqMDUwZjAxdXAzaGxyeW5rdS9tYXN0ZXIiLCJtYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC1ldS1jZW50cmFsLTEtc2hhcmVkLWV1YzEtMDIuaHlncmFwaC5jb20vIiwic3ViIjoiOTNiMWU4ZTEtMDEwZi00ZmU2LTk2ZTctN2U2NWU1MGE2MjIwIiwianRpIjoiY2x3cndxNW0zMDA1NzA3dzUwOGp6ZHFtOSJ9.bVlE0MT92G2zhGmxg4ropJFSKvtn_SDdXY0RxH4pjoXx_BGhquswnNG1B-nOS3ecXIepmY23WeSyxcK5NUbaadYlA4yfoi6typnE3Ofa1VH7YrhKCGoxSauZhu1wa7NJ8zehBjpiH6vWUHKWV5fhGvIHgaipnpMvQBC2512vCHuzoZvd0kdDP6qccm2IzI420C3nhY15Uqzm5vci1p80eegfIIaXzsFZCcU3auOP2uZPd5Q9WNAnnuy_pTuq5Ago6iW3XwAGuIWY2Vl47LZMH4ZYMaSVwRxaTso1vpunvnIWYy0VaMhGDFPQ6PZ5QQZHYIKHnVPUFEUaiqMspw-n4Q4i9wMdbFiAw-gJDKCbuOOtQKjbWDs7ZhToBRZExJFVPE48KRCUM-wA_XWZyfOGUTJNpebPozTtcRsv4Dk0481L8Pod3veSzt3EJV06c14qDkWec6opv7qzNaaPMl28eQvCwqyUBLpK8ItAkUk4mP9qRG1tCJ31vnJzXJg8Hep9pHf7zeyZeZAO6vxNnuVrzaIx7vSheb3dEEz6MONdYVDnvRtsVa6NpbOU5cGTYogET6L1Pg1Yg4NQBaD6AofxCMZp41a2miMU17gdOyiUxr48REeJCnZIK5J832M1XWl0O0fbdck6VIkxYWnZ6rehaokJv1IafeMnXBCxVLnI1Fs";

// Middleware to add token in headers
const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    }
  }));

  return forward(operation);
});

// HTTP connection API
const httpLink = createHttpLink({
  uri: 'https://eu-central-1-shared-euc1-02.cdn.hygraph.com/content/clslyentj050f01up3hlrynku/master',
})

// Cache implementation
const cache = new InMemoryCache()

// Create the apollo client
const apolloClient = new ApolloClient({
  link: concat(authMiddleware, httpLink),
  cache,
})


const apolloProvider = createApolloProvider({
  defaultClient: apolloClient,
});

export default apolloProvider;