<template>
  <LoaderView/>
  <section class="projet-detail">

    <div class="title">
      <router-link to="/projets">
        <svg xmlns="http://www.w3.org/2000/svg" width="56.83" height="29.23" viewBox="0 0 56.83 29.23">
          <line stroke-linecap="round" stroke-width="3px" x1="55.02" y1="14.79" x2="1.81" y2="14.79"/>
          <line stroke-linecap="round" stroke-width="3px" class="cls-1" x1="16.52" y1="27.2" x2="1.81" y2="14.79"/>
          <line stroke-linecap="round" stroke-width="3px" class="cls-1" x1="16.52" y1="2.2" x2="1.81" y2="14.79"/>
        </svg>
      </router-link>
      <Skeletor v-if="$apollo.queries.projet.loading" width="60%" as="h1" /> 
      <template v-else>
        <h1 v-if="this.lang === 'fr'">{{ this.projet.title }}</h1>
        <h1 v-if="this.lang === 'en'">{{ this.projet.localizations[0].title }}</h1>
      </template>
    </div>

    <Skeletor v-if="$apollo.queries.projet.loading" as="div" size="100%" /> 
    <div v-else class="image">
      <template v-for="(img,index) in this.projet.images" :key="index">
        <img  :src="img.url" :alt="img.alt" :data-id="img.id" :class="{active: img.isMain}" />
        <span v-if="!!img.credit" class="credit">
          Photo : {{ img.credit }}
        </span>
      </template>
    </div>

    <div class="text">
      <div v-if="$apollo.queries.projet.loading" class="tags">
        <Skeletor width="25%" as="div" /> 
        <Skeletor width="25%" as="div" /> 
      </div>
      <div v-else class="tags">
        <template v-if="this.lang === 'fr'">
          <div v-for="(t,index) in this.projet.tag" :key="index">#{{ t }}</div>
        </template>
        <template v-else>
          <div v-for="(t,index) in this.projet.localizations[0].tag" :key="index">#{{ t }}</div>
        </template>
      </div>
      <!-- //SKELETON -->
      <template v-if="$apollo.queries.projet.loading">
        <Skeletor as="p"/> 
        <Skeletor as="p"/> 
        <Skeletor as="p"/> 
      </template>
      <template v-else>
        <p v-if="this.lang === 'fr'"><strong>Architecte :</strong> {{ this.projet.architect }}</p>
        <p v-else class="customer"><strong>Architect :</strong> {{ this.projet.localizations[0].architect }}</p>
        <template v-if="!!this.projet.customer">
          <p v-if="this.lang === 'fr'" class="customer"><strong>Client :</strong> {{ this.projet.customer }}</p>
          <p v-else class="customer"><strong>Customer :</strong> {{ this.projet.localizations[0].customer }}</p>
        </template>
        <template v-if="!!this.projet.period">
          <p v-if="this.lang === 'fr'" class="period"><strong>Période de travaux :</strong> {{ this.projet.period }}</p>
          <p v-else class="period"><strong>Work period :</strong> {{ this.projet.localizations[0].period }}</p>
        </template>
        <p v-if="!!this.projet.budget" class="budget"><strong>Budget :</strong> {{ this.projet.budget }}</p>
        <template v-if="!!this.projet.description">
          <p v-if="this.lang === 'fr'" v-html="this.projet.description.html" class="description"></p>
          <p v-else v-html="this.projet.localizations[0].description.html" class="description"></p>
        </template>
      </template>
    </div>

    <div v-if="!$apollo.queries.projet.loading && this.projet.images.length>1" class="all-image">
      <div v-for="(img,index) in this.projet.images" :key="index">
        <img :src="img.url" :alt="img.alt" :data-id="img.id" :class="{active: img.isMain}" @click="handleImgOnClick" />
      </div>
    </div>

  </section>
</template>
  
<script>
import gql from 'graphql-tag';
import gsap from 'gsap';
import { mapState } from 'vuex';
import LoaderView from "../components/LoaderView.vue";

export default {
    name:"ProjetsView",
    components: {
      LoaderView,
    },
    apollo: {
      projet: {
        query: gql`query projet($slug: String!,$locales: [Locale!]!) {
          projet(where: {slug: $slug}, locales: $locales) {
            title
            description{ html }
            period
            customer
            architect
            budget
            tag
            locale
            images {
              id
              alt
              isMain
              url
              width
              credit
            }
            localizations {
              title
              description{ html }
              period
              architect
              customer
              budget
              tag
              locale
            }
          }
        }`,
        // Static parameters
        variables() {
          return{
            slug: this.$route.params.title,
            locales: ['fr','en']
          }
        }
      },
    },
    computed:mapState([
      'lang'
    ]),
    methods:{
      handleImgOnClick(e){
        const id = e.target.dataset.id;
        
        const img = document.querySelector(`.image img[data-id = ${id}]`);
        const imgToHide = document.querySelectorAll(`.image img.active`);
        imgToHide.forEach(e=>{
          gsap.to(e,{scale:0,autoAlpha:0})
          e.classList.remove("active");
        });
        gsap.to(img,{scale:1,autoAlpha:1});
        img.classList.add("active");
      }
    },
}
</script>

<style lang="scss" scoped>
  @import "../styles/includes.scss";

  .projet-detail{
    display: grid;
    grid-template-rows: auto 40vh 8vh auto;
    row-gap: 1%;
    position: relative;
    width: 100%;
    @include smartphone-landscape{
      grid-template-rows: auto 60vh 8vh auto;
      width: 70%;
      margin-inline: auto;            
    }
    @include tablet{
      grid-template-rows: auto 60vh 8vh auto;
      width: 70%;
      margin-inline: auto;
    }
    @include tablet-portrait{
      grid-template-rows: auto 30vh 8vh auto;
    }
    @include computer{
      display: grid;
      grid-template-rows: repeat(3,auto);
      grid-template-columns: 55% 2.5% 40%;
      row-gap: 2%;
      height: auto;
      width: 77%;
      margin-inline: 14% 0;
    }
    & .title{
      grid-row: 1/2;
      display: flex;
      align-items: center;
      width: 90%;
      margin-inline: auto;
      gap: 5%;
      @include tablet{
        width: 100%;
      }
      @include computer{
        position: sticky;
        top: 10.6vw;
        grid-column: 1/4;
        gap: 2%;
        width: 100%;
      }
      & svg{
        stroke:$black;
        transition: all 0.25s ease;
        &:hover{
          transform: scale(1.2,1);
          transition: all 0.25s ease;
        }
      }
      & h1{
        font-size: clamp(28px, 7.8vw, 85px);
        letter-spacing: -2px;
        text-transform: uppercase;
        font-weight: normal;
        margin: 0;
        color: $black;
      }
    }
    & .image{
      grid-row: 2/3;
      position: relative;
      contain: paint;
      @include computer{
        position: sticky;
        top: 34.4%;
        grid-column: 1/2;
        min-height: inherit;
        height: 52vh;
      }
      & img{
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        transform: scale(0);
        opacity: 0;
        visibility: hidden;
        &.active{
          transform: scale(1);
          opacity: 1;
          visibility: visible;            
        }
        &:hover+span{
          opacity: 1;
          visibility: visible;
          transition: all 0.25s ease;
        }
      }
      & span.credit{
        position:absolute;
        bottom:1rem;
        right:1rem;
        padding: 0.5rem;
        background-color: $white;
        border-radius: 10px;
        color: $black;
        opacity:0.8;
        font-size: 12px;
        opacity: 0;
        visibility: hidden;
        transition: all 0.25s ease;
      }
    }
    & .text{
      width: 90%;
      grid-row: 4/5;
      margin: 2rem auto 15% auto;
      @include tablet{
        margin: 5% auto 15% auto;
        width: 100%;
      }
      @include computer{
        grid-column: 3/4;
        grid-row: 2/3;
        margin: 0;
      }
      & .tags{
        display: flex;
        flex-flow: row wrap;
        gap: 1vw;
        font-size: clamp(18px, 1vw, 24px);
        &>div{
          color: $black;
          border: 1px solid $black;
          padding: 0.5rem 1rem;
          border-radius: 15px;
        }
      }
      & p{
        color: $black;
        font-size: clamp(18px, 1vw, 24px);
        @include computer{
          min-width: 450px;
          padding-right:1rem;
        }
        &.description{
          margin-top: 2rem;
        }
      }
    }
  }
  .all-image{
    grid-row: 3/4;
    margin-inline: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1%;
    @include tablet{
      justify-content: flex-start;
    }
    @include computer{
      position: fixed;
      top: 89%;
      grid-column: 1/2;
    }
    & >div{
      width: auto;
      height: 100%;
      max-width: 30vw;
      contain: paint;
      overflow: hidden;
      cursor: pointer;
      @include tablet{
        width: 14vw;
        height: auto;
        max-width: 6vw;
        max-height: 4vw;
      }
      &>img{
        height: 100%;
        width: auto;
        object-fit: cover;
        object-position: center;
        transition: all 0.25s ease;
        @include tablet{
          width: 100%;
          height: auto;
        }
      }
      &:hover>img{
        transform: scale(1.2);
        transition: all 0.25s ease;
      }
    }
  }
</style>