<template>
  <div ref="loader" class="loader">
    <div ref="svg" class="loader-container">
      <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 591.53 297.26">
        <path class="letter" d="M13.25,89.7v38.64H0V28.98H30.5c21.11,0,33.81,11.32,33.81,30.36s-12.7,30.36-33.81,30.36H13.25Zm0-49.54v38.36H31.19c12.28,0,19.6-7.18,19.6-19.18s-7.73-19.18-20.7-19.18H13.25Z"/>
        <path class="letter" d="M138.21,128.34v-38.64h-49.27v38.64h-13.25V28.98h13.25v49.54h49.27V28.98h13.25v99.36h-13.25Z"/>
        <path class="letter" d="M210.9,27.33h.28l45.82,101.02h-14.08l-17.29-38.45h-29.49l-16.98,38.45h-14.08L210.9,27.33Z"/>
        <path class="letter" d="M268.35,124.2v-13.11c7.04,3.45,17.25,7.04,28.01,7.04,12.42,0,21.67-5.11,21.67-14.21s-5.38-14.21-22.08-20.01c-19.04-6.62-28.01-14.77-28.01-29.95,0-16.84,14.08-26.63,33.12-26.63,9.94,0,19.18,2.62,24.7,5.11v12.56c-5.52-2.9-15.04-5.8-24.01-5.8-12.28,0-20.84,5.52-20.84,14.35,0,8.14,4.83,13.94,21.8,19.87,19.04,6.9,28.29,14.63,28.29,29.53,0,16.97-13.11,27.05-33.95,27.05-11.45,0-22.08-2.9-28.7-5.8Z"/>
        <path class="letter" d="M341.93,128.34V28.98h61v11.18h-47.75l.04,38.5h33.47v11.18h-33.47l-.04,27.32h49.82v11.18h-63.07Z"/>
        <rect x="419.08" y=".33" width="13.26" height="296.94"/>
        <path id="ball" d="M511.01,0c-43.44,0-78.66,35.22-78.66,78.66,0,43.44,35.22,78.66,78.66,78.66,43.44,0,78.66-35.22,78.66-78.66,0-43.44-35.22-78.66-78.66-78.66Zm-.98,125.57c-12.69,0-23.55-7.76-28.12-18.8h12.54c3.38,4.59,8.8,7.58,14.94,7.58,10.25,0,18.57-8.31,18.57-18.57s-8.31-18.57-18.57-18.57c-5.95,0-11.23,2.81-14.63,7.16h-13.19s0,0,0-.01V31.75h50.45v10.58h-37.89v26.88c4.63-2.84,10.07-4.49,15.9-4.49,16.8,0,30.43,13.62,30.43,30.43s-13.62,30.43-30.43,30.43Z"/>
      </svg>
      <div class="progress-bar">
        <div class="progress" :style="{ width: percentage + '%' }"></div>
      </div>
    </div>
    <div ref="veil" class="loader-veil"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import gsap from 'gsap';


export default {
  name: 'LoaderView',
  computed:mapState([
      'documentReady'
    ]),
  data() {
    return {
      loading: true,
      percentage: 0,
      letters : null,
      keyframe : null
    };
  },
  mounted(){
    this.documentReady && this.setupRemoveLoader();

    this.letters = document.querySelectorAll(".letter");
    this.setupLetterAnimation();
    const speed = this.estimateConnectionSpeed();
    let increment = speed === 'slow' ? 2.5 : 5;
    let progress = 0;
    const interval = setInterval(() => {
      progress += Math.random() * increment;
      this.percentage = progress;
      if (progress >= 100) {
        clearInterval(interval);
        this.loading = false;
      }
    }, 100);
  },
  methods:{
    estimateConnectionSpeed() {
      const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
      if (connection) {
        switch (connection.effectiveType) {
          case 'slow-2g':
          case '2g':
          case '3g':
            return 'slow';
          case '4g':
            return 'fast';
          default:
            return 'unknown';
        }
      }
      return 'unknown';
    },
    setupLetterAnimation (){
      const animation = gsap.to(this.letters,{
      keyframes: {
                scaleY: [1, 2, 1],
                ease: "power1.inOut"
            },
      stagger:0.2,
      duration: 2,
      repeat:-1
    });
    this.keyframe = animation;
    },
    cleanupAnimations(){
      this.keyframe.kill();
    },
    setupRemoveLoader(){
      gsap.timeline({})
          .to(this.$refs.veil, { y:"-150%",duration:1.5},"0")
          .to(this.$refs.svg, { autoAlpha: 0,duration:0.5 },"0")
          .to(this.$refs.loader, { autoAlpha: 0,duration:1,zIndex:-1 },"1");
          document.querySelector("body").style.overflow = "overlay";
    }
  },
  beforeUnmount() {
    this.cleanupAnimations();
  },
  watch:{
    documentReady:{
      handler(documentReady) {
        if(documentReady){
          this.setupRemoveLoader()
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import "../styles/includes.scss";

.loader{
    position: fixed;
    top: 0;
    left: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    width: 100vw;
    height: 100vh;
    background-color: $white;
    z-index: 100;
    & svg{
      fill: $black;
      mix-blend-mode: exclusion;
      width: 60vw;
      @include tablet{
        width: 30vw;
      }
    }
}

.loader-text{
    display: flex;
    align-items: center;
    justify-content: center;
    color: $black;
    text-align: center;
}

.percentage {
  font-size: clamp(16px, 1.5vw, 32px);
  color: $black;
  mix-blend-mode: exclusion;
  margin-top: 1rem;
}

.progress-bar {
  width: 100%;
  height: 6.26px;
  fill: $black;
  mix-blend-mode: exclusion;
  margin: 0 auto;
  @include tablet{
    height: 13.26px;
  }
}

.progress {
  height: 100%;
  background-color: $black;
  transition: width 0.5s ease;
}

.loader-veil{
  position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(50%);
    background: $black;
    width: 100%;
    height: 100%;
}

</style>