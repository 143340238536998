<template>
  <MenuLayout></MenuLayout>
  <router-view/>
</template>

<script>
import MenuLayout from "./components/layout/MenuLayout.vue";

export default {
  name: 'App',
  data() {
    return{
      appOn : false
    }
  },
  components: {
    MenuLayout,
  },
  mounted(){
    if(!this.appOn){
      document.addEventListener('readystatechange', () =>{
        setTimeout(()=>{
          this.$store.dispatch("setDocumentReady", true);
        },1000)
      });
    }
    this.appOn = true
    //Light mode
    if (sessionStorage.getItem("mode") === 'light') {
      this.$store.dispatch("setScreenMode",'light');
      sessionStorage.setItem("mode", "light");
    } else {
      this.$store.dispatch("setScreenMode",'dark');
      sessionStorage.setItem("mode", "dark");
    }
    //Lang mode
    if(sessionStorage.getItem("lang") === 'en'){
      this.$store.dispatch("setLang",'en');
    }else{
      this.$store.dispatch("setLang",'fr');
    }
  },
}
</script>

<style lang="scss">
@import "./styles/includes.scss";
@import 'vue-skeletor/dist/vue-skeletor.css';


#app {
  font-family: '"brother-1816"', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  outline: 0;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow: clip;
}

//----------HEADING---------//

h2{
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #f7f7f7;
  mix-blend-mode: exclusion;
}

h3{
  font-size: clamp(26px, 2.5vw, 48px);
  text-transform: uppercase;
  letter-spacing: 5px;
  margin: 0;
}

h4{
  font-size: clamp(12px, 7cqw, 32px);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0;
}

h5{
  font-size: clamp(14px ,1vw, 18px);
  text-transform: uppercase;
  letter-spacing: 1.5px;
  margin: 0;
}

//----------SCROLL BAR---------//

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background-clip: padding-box;
    background-color: $black;
}

//----------GENERAL---------//

.icone{
  display:flex;
  justify-content:center;
  align-items:center;
  width: 13.125vw;
  max-width: 60px;
  height: 13.125vw;
  max-height: 60px;
  border-radius: 100px;
  border: 1px solid $white;
  @include tablet{
    width: 3.125vw;
    height: 3.125vw;
  }
  @include tablet-portrait{
    width: 5.125vw;
    height: 5.125vw;
  }
  &:hover{
    background-color: $white;
    & svg{
      fill: $black;
    }
  }
  & svg{
    fill: $white;
    width: 50%;
  }
}

.toggle-btn {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    border-radius: 50px;
    border: 1px solid $black;
    & input{
        opacity: 0;
        width: 0;
        height: 0;
    }
    & .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $black;
        border-radius: 50px;
        -webkit-transition: .4s;
        transition: .4s;
        &:before{
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 3px;
            background-color: $white;
            border-radius: 50px;
            -webkit-transition: .4s;
            transition: .4s;    
        }
    }
    & .toggle{
        &:checked + .slider{
            background-color: $white;
        }
        &:focus + .slider{
            box-shadow: 0 0 1px $white;
        }
        &:checked + .slider:before{
            background-color: $black;
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
}
  
}

</style>
