import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Skeletor } from 'vue-skeletor';
import apolloProvider from '../apollo-client';

createApp(App)
.use(store)
.use(router)
.use(apolloProvider)
.component(Skeletor.name, Skeletor)
.mount('#app')


