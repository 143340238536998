import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProjetsView from '../views/ProjetsView'
import ProjetView from '../views/ProjetView.vue'
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

gsap.registerPlugin(ScrollTrigger);

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/projets',
    name: 'projets',
    component: ProjetsView
  },
  {
    path: '/projet/:title',
    component: ProjetView,
  },
]


const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to) {
    if(to.hash){
      const element = document.querySelector(to.hash);
      const elementPosition = element.getBoundingClientRect().top;
      if(element) {
        scrollTo(0,elementPosition + window.scrollY);
        setTimeout(() => {
          ScrollTrigger.refresh();
        }, 300);
      }
    }else{
      scrollTo(0, 0)
    }
  },
  routes
})

export default router

