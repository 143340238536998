<template>
    <footer id="contact">
        <div>
            <router-link to="/" class="logo">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 591.53 297.26">
                    <path d="M13.25,89.7v38.64H0V28.98H30.5c21.11,0,33.81,11.32,33.81,30.36s-12.7,30.36-33.81,30.36H13.25Zm0-49.54v38.36H31.19c12.28,0,19.6-7.18,19.6-19.18s-7.73-19.18-20.7-19.18H13.25Z"/>
                    <path d="M138.21,128.34v-38.64h-49.27v38.64h-13.25V28.98h13.25v49.54h49.27V28.98h13.25v99.36h-13.25Z"/>
                    <path d="M210.9,27.33h.28l45.82,101.02h-14.08l-17.29-38.45h-29.49l-16.98,38.45h-14.08L210.9,27.33Z"/>
                    <path d="M268.35,124.2v-13.11c7.04,3.45,17.25,7.04,28.01,7.04,12.42,0,21.67-5.11,21.67-14.21s-5.38-14.21-22.08-20.01c-19.04-6.62-28.01-14.77-28.01-29.95,0-16.84,14.08-26.63,33.12-26.63,9.94,0,19.18,2.62,24.7,5.11v12.56c-5.52-2.9-15.04-5.8-24.01-5.8-12.28,0-20.84,5.52-20.84,14.35,0,8.14,4.83,13.94,21.8,19.87,19.04,6.9,28.29,14.63,28.29,29.53,0,16.97-13.11,27.05-33.95,27.05-11.45,0-22.08-2.9-28.7-5.8Z"/>
                    <path d="M341.93,128.34V28.98h61v11.18h-47.75l.04,38.5h33.47v11.18h-33.47l-.04,27.32h49.82v11.18h-63.07Z"/>
                    <rect x="419.08" y=".33" width="13.26" height="296.94"/>
                    <path d="M511.01,0c-43.44,0-78.66,35.22-78.66,78.66,0,43.44,35.22,78.66,78.66,78.66,43.44,0,78.66-35.22,78.66-78.66,0-43.44-35.22-78.66-78.66-78.66Zm-.98,125.57c-12.69,0-23.55-7.76-28.12-18.8h12.54c3.38,4.59,8.8,7.58,14.94,7.58,10.25,0,18.57-8.31,18.57-18.57s-8.31-18.57-18.57-18.57c-5.95,0-11.23,2.81-14.63,7.16h-13.19s0,0,0-.01V31.75h50.45v10.58h-37.89v26.88c4.63-2.84,10.07-4.49,15.9-4.49,16.8,0,30.43,13.62,30.43,30.43s-13.62,30.43-30.43,30.43Z"/>
                </svg>
            </router-link>
            <div class="signature">
                <p><strong>ROSSIER Gilles</strong></p>
                <p>Founder, directeur des travaux diplômé</p>
            </div>
            <div class="icones">
                <a class="icone"  href="https://www.linkedin.com/in/gilles-rossier-911aa0192/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" width="22.5" height="22.5" viewBox="0 0 21.328 21.327">
                        <path d="M4.774,21.337H.352V7.1H4.774ZM2.561,5.156A2.573,2.573,0,1,1,5.121,2.571,2.582,2.582,0,0,1,2.561,5.156ZM21.323,21.337H16.911V14.406c0-1.652-.033-3.77-2.3-3.77-2.3,0-2.651,1.795-2.651,3.651v7.05H7.544V7.1h4.241V9.04h.062a4.646,4.646,0,0,1,4.184-2.3c4.475,0,5.3,2.947,5.3,6.774v7.822Z"/>
                    </svg>
                </a>
                <a class="icone" href="https://www.instagram.com/phase_5/" target="_blank">
                    <svg xmlns="http://www.w3.org/2000/svg" height="26.5" width="26.5" viewBox="0 0 448 512">
                        <path  d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/>
                    </svg>
                </a>
            </div>
        </div>
        <div>
            <div>
                <p v-if="this.lang === 'fr'"><strong>Adresse</strong></p>
                <p v-else><strong>Addresse</strong></p>
                <p>co/PHASE5</p> 
                <p>Le Bivouac</p> 
                <p>Route de Verbier Station 45</p> 
                <p>CH-1936 Verbier</p> 
            </div>
        </div>
        <div>
            <div>
                <p v-if="this.lang === 'fr'"><strong>Téléphone</strong></p>
                <p v-else><strong>Phone number</strong></p>
                <a href="tel:+41 79 586 96 78">+41 79 586 96 78</a>
            </div>
            <div>
                <p><strong>E-mail</strong></p>
                <a href="mailto:gilles@phase-5.ch">gilles@phase-5.ch</a>
            </div>
        </div>
        <div  v-if="this.lang === 'fr'" class="real">
            ©2024 Phase 5 - Site réalisé par <a href="https://www.abclic.ch" target="_blank">Abclic</a> et <a href="https://www.ananaweb.ch" target="_blank">Ananaweb</a>
        </div>
        <div  v-else class="real">
            ©2024 Phase 5 - Website realized by <a href="https://www.abclic.ch" target="_blank">Abclic</a> and <a href="https://www.ananaweb.ch" target="_blank">Ananaweb</a>
        </div>
    </footer>
</template>

<script>
import { mapState } from 'vuex';

export default ({
    name: "FooterLayout",
    computed:mapState([
      'lang'
    ]),
})
</script>

<style lang="scss" scoped>
@import "../../styles/includes.scss";

footer{
    position: relative;
    display: flex;
    flex-flow: column wrap;
    background-color: $black;
    padding: 10%;
    padding-bottom: 0;
    color: $white;
    z-index: 10;
    @include tablet{
        flex-flow: row wrap;
        box-sizing: border-box;
        max-height: inherit;
        padding: 25% 14.5% 0 14.5%;
        justify-content: space-between;
        z-index: inherit;
        &>div:nth-child(1),>div:nth-child(2){
            border-right: 1px solid $white;
        }
    }
    @include tablet-portrait{
        height: auto;
    }
    .logo{
        display: block;
        width: clamp(250px, 80%, 300px);
        path,rect{
            fill: $white;
        }
    }
    .logo,.signature{
        display: none;
        @include tablet{
            display:block;
        }
    }
    &>div:not(.real){
        display: flex;
        flex-direction: column;
        padding-bottom: 1.5rem;
        padding-right: 5vw;
        gap: 1rem;
        font-style: normal;
        font-size: clamp(16px, 1.3vw, 24px);
        @include smartphone-landscape{
            gap: 2rem;
        }
        @include tablet{
            padding-bottom: 3rem;
            gap: 2rem;
        }
        & div{
            flex: 0 0 auto;
        }
        & p{
            margin: 0.5rem 0;
        }
    }
    & .icones{
        display: flex;
        flex-flow: row nowrap;
        min-width: 10%;
        gap: clamp(2.5%,16px,10%);
        @include smartphone-landscape{
            min-width: 20%;
        }
        @include tablet-portrait{
            min-width: 20%;
        }
    }
    & .real{
        width: 100%;
        text-align: center;
        font-size: clamp(12px, 1.2vw, 17px);
        border-top: 1px solid $white;
        padding: 1.5rem 0;
        @include tablet{
            padding: 2rem 0;
        }
    }
}
</style>