<template>
  <header>
        <router-link to="/" class="logo" @click="closeMenu">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 591.53 297.26">
            <path d="M13.25,89.7v38.64H0V28.98H30.5c21.11,0,33.81,11.32,33.81,30.36s-12.7,30.36-33.81,30.36H13.25Zm0-49.54v38.36H31.19c12.28,0,19.6-7.18,19.6-19.18s-7.73-19.18-20.7-19.18H13.25Z"/>
            <path d="M138.21,128.34v-38.64h-49.27v38.64h-13.25V28.98h13.25v49.54h49.27V28.98h13.25v99.36h-13.25Z"/>
            <path d="M210.9,27.33h.28l45.82,101.02h-14.08l-17.29-38.45h-29.49l-16.98,38.45h-14.08L210.9,27.33Z"/>
            <path d="M268.35,124.2v-13.11c7.04,3.45,17.25,7.04,28.01,7.04,12.42,0,21.67-5.11,21.67-14.21s-5.38-14.21-22.08-20.01c-19.04-6.62-28.01-14.77-28.01-29.95,0-16.84,14.08-26.63,33.12-26.63,9.94,0,19.18,2.62,24.7,5.11v12.56c-5.52-2.9-15.04-5.8-24.01-5.8-12.28,0-20.84,5.52-20.84,14.35,0,8.14,4.83,13.94,21.8,19.87,19.04,6.9,28.29,14.63,28.29,29.53,0,16.97-13.11,27.05-33.95,27.05-11.45,0-22.08-2.9-28.7-5.8Z"/>
            <path d="M341.93,128.34V28.98h61v11.18h-47.75l.04,38.5h33.47v11.18h-33.47l-.04,27.32h49.82v11.18h-63.07Z"/>
            <rect x="419.08" y=".33" width="13.26" height="296.94"/>
            <path d="M511.01,0c-43.44,0-78.66,35.22-78.66,78.66,0,43.44,35.22,78.66,78.66,78.66,43.44,0,78.66-35.22,78.66-78.66,0-43.44-35.22-78.66-78.66-78.66Zm-.98,125.57c-12.69,0-23.55-7.76-28.12-18.8h12.54c3.38,4.59,8.8,7.58,14.94,7.58,10.25,0,18.57-8.31,18.57-18.57s-8.31-18.57-18.57-18.57c-5.95,0-11.23,2.81-14.63,7.16h-13.19s0,0,0-.01V31.75h50.45v10.58h-37.89v26.88c4.63-2.84,10.07-4.49,15.9-4.49,16.8,0,30.43,13.62,30.43,30.43s-13.62,30.43-30.43,30.43Z"/>
        </svg>
        </router-link>
        <div @click="handleBurgerClick(this.menuState)" class="burger">
          <svg ref="burger" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 38.11 38.69">
            <circle cx="4.94" cy="4.94" r="4.92"/>
            <circle cx="33.19" cy="4.94" r="4.92"/>
            <circle cx="19.03" cy="4.94" r="4.92" class="burger_hide_point"/>
            <circle cx="4.91" cy="31.77" r="4.92"/>
            <circle cx="33.19" cy="31.77" r="4.92"/>
            <circle cx="19.05" cy="31.77" r="4.92" class="burger_hide_point"/>
            <circle cx="4.87" cy="18.35" r="4.92" class="burger_hide_point"/>
            <circle cx="33.19" cy="18.35" r="4.92" class="burger_hide_point"/>
            <circle cx="19.03" cy="18.35" r="4.92"/>
        </svg>
        </div>
        <nav ref="navigation" class="navigation">
          <div ref="bar" class="top-bar">
            <div class="mode">
              <label class="toggle-btn">
                  <input class="toggle" type="checkbox"  @click="changeColor" :checked="this.mode === 'light' ? true : false" />
                  <span class="slider round"></span>
              </label>
              <span ref="color_mode">Sombre</span>
            </div>
            <div class="lang" :data-lang="this.lang" @click="changeLang">
             {{ this.lang === 'fr'? 'FR | EN' : 'EN | FR' }}
            </div>
          </div>
          <div class="block"></div>
          <ul>
            <li class="navlink-projets">
              <router-link to="/projets" @click="closeMenu">
                <span>P</span>
                <span>r</span>
                <span>o</span>
                <span>j</span>
                <span>e</span>
                <span>t</span>
                <span>s</span>
              </router-link>
            </li>
            <li class="navlink-office">
              <router-link to="/#office" @click="closeMenu">
                <span>O</span>
                <span>f</span>
                <span>f</span>
                <span>i</span>
                <span>c</span>
                <span>e</span>
              </router-link>
            </li>
            <li class="navlink-services">
              <router-link to="/#services" @click="closeMenu">
                <span>S</span>
                <span>e</span>
                <span>r</span>
                <span>v</span>
                <span>i</span>
                <span>c</span>
                <span>e</span>
                <span>s</span>
              </router-link >
            </li>
            <li class="navlink-references">
              <router-link to="/#references" @click="closeMenu">
                <span>R</span>
                  <span>
                    <template v-if="this.lang === 'fr'">é</template>
                    <template v-if="this.lang === 'en'">e</template>
                  </span>
                  <span>f</span>
                  <span>
                    <template v-if="this.lang === 'fr'">é</template>
                    <template v-if="this.lang === 'en'">e</template>
                  </span>
                  <span>r</span>
                  <span>e</span>
                  <span>n</span>
                  <span>c</span>
                  <span>e</span>
                  <span>s</span>

              </router-link>
            </li>
            <li class="navlink-contact">
              <router-link to="/#contact" @click="closeMenu">
                <span>C</span>
                <span>o</span>
                <span>n</span>
                <span>t</span>
                <span>a</span>
                <span>c</span>
                <span>t</span>
              </router-link>
            </li>
          </ul>
        </nav>
  </header>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from 'gsap/all';
import { mapState } from 'vuex';

gsap.registerPlugin(ScrollTrigger);

export default {
  name: 'MenuLayout',
  data() {
    return{
      menuState:false,
      burger_hide_point:null,
      letters : {
        projets:null,
        office:null,
        services:null,
        references:null,
        contact:null,
      }
    }
  },
  computed:mapState([
      'lang',
      'mode'
    ]),
  mounted() {
    this.burger_hide_point = document.querySelectorAll(".burger_hide_point");
    this.letters.projets = document.querySelectorAll(".navigation .navlink-projets span");
    this.letters.office = document.querySelectorAll(".navigation .navlink-office span");
    this.letters.services = document.querySelectorAll(".navigation .navlink-services span");
    this.letters.references = document.querySelectorAll(".navigation .navlink-references span");
    this.letters.contact = document.querySelectorAll(".navigation .navlink-contact span");
  },
  methods:{
    handleBurgerClick(state){
      state && this.closeMenu();
      !state && this.openMenu();
    },
    openMenu(){
      this.menuState = true;
      document.querySelector("body").style.overflow = "hidden";
      gsap.to(this.$refs.navigation,{width:"100vw", x:0,ease:"power1"});
      gsap.to(this.$refs.burger,{rotate:360});
      this.burger_hide_point.forEach(el=>gsap.to(el,{autoAlpha:0}));
      gsap.to(this.letters.projets,{x:0, ease: "power1",stagger:0.1,delay:0.1})
      gsap.to(this.letters.office,{x:0, ease: "power1",stagger:0.1,delay:0.2})
      gsap.to(this.letters.services,{x:0, ease: "power1",stagger:0.1,delay:0.3})
      gsap.to(this.letters.references,{x:0, ease: "power1",stagger:0.1,delay:0.4})
      gsap.to(this.letters.contact,{x:0, ease: "power1",stagger:0.1,delay:0.5})
      gsap.to(this.$refs.navigation,{"--height": "100%",delay:1})
      gsap.to(this.$refs.bar,{autoAlpha:1},"1")
    },
    closeMenu(){
      this.menuState = false;
      document.querySelector("body").style.overflow = "overlay";
      document.querySelector("body").style.overflowX = "hidden";
      gsap.to(this.$refs.bar,{autoAlpha:0})
      gsap.to(this.$refs.burger,{rotate:-360});
      this.burger_hide_point.forEach(el=>gsap.to(el,{autoAlpha:1}))
      gsap.to(this.$refs.navigation,{width:0, x:"-100%", "--height": 0, delay:0.5})
      gsap.to(this.letters.projets,{x:"-1500%" ,stagger:0.01})
      gsap.to(this.letters.office,{x:"-1500%",stagger:0.01})
      gsap.to(this.letters.services,{x:"-1500%",stagger:0.01})
      gsap.to(this.letters.references,{x:"-1500%",stagger:0.01})
      gsap.to(this.letters.contact,{x:"-1500%",stagger:0.01})
      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 500);
    },
    changeColor(e){
      if(e.target.checked){
        this.$store.dispatch("setScreenMode",'light');
      }else{
        this.$store.dispatch("setScreenMode",'dark');
      }
    },
    changeLang(e){
      let currentLang = e.target.dataset.lang;
      if(currentLang === 'fr'){
        currentLang = 'en';
        e.target.dataset.lang = "en";
        // e.target.innerText = "EN | FR";
        sessionStorage.setItem("lang", "en");
      }else{
        currentLang = 'fr';
        e.target.dataset.lang = "fr"
        // e.target.innerText = "FR | EN";
        sessionStorage.setItem("lang", "fr");
      }
      this.$store.dispatch("setLang",currentLang);
    }
  },
  watch:{
    mode:{
      handler(mode){
        const r = document.querySelector(':root');
        if(mode === 'dark'){
          r.style.setProperty('--white', '#333');
          r.style.setProperty('--black', '#F7F7F7');
          r.style.setProperty('--filter', 'grayscale(1) contrast(0) brightness(0)');
          this.$refs.color_mode.innerText = this.lang === 'fr'? "Sombre":"Dark";
          sessionStorage.setItem("mode", "dark");
        }else{
          r.style.setProperty('--white', '#F7F7F7');
          r.style.setProperty('--black', '#333');
          r.style.setProperty('--filter', 'grayscale(1) contrast(0) brightness(2)');
          this.$refs.color_mode.innerText = this.lang === 'fr'? "Claire":"Light";
          sessionStorage.setItem("mode", "light");
        }
      }
    },
    lang:{
      handler(){
        if(this.mode === 'light'){
          this.$refs.color_mode.innerText = this.lang === 'fr'? "Claire":"Light";
        }else{
          this.$refs.color_mode.innerText = this.lang === 'fr'? "Sombre":"Dark";
        }

      }
    }
  }
}
</script>

<style scoped lang="scss">

@import "../../styles/includes.scss";

header{
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%;
  height: var(--header-height);
  box-sizing: border-box;
  padding: 0 2.5%;
  z-index: 80;
}

.navigation{
  position: fixed;
  top: 0;
  left: 0;
  display:flex;
  align-items:center;
  box-sizing: border-box;
  height: 100vh;
  width: 0;
  transform:translateX(-100%);
  background: $white;
  z-index: 90;
  --height:0;
  contain: paint;
  @include tablet{      
    padding-left: 13.333vw;
  }
  &::before{
    content: "";
    position: absolute;
    top: 80px;
    left: 0;
    width: 100%;
    height: 0.8vw;
    background-color: #F7F7F7;
    mix-blend-mode: exclusion;
    @include smartphone-landscape{
      height: 0.3vw;
    }
    @include tablet{
      left: 14.15vw;
      top: auto;
      bottom: 0;
      width: 0.321vw;
      height: calc(var(--height) - 9.3vw);
    }
  }
  & .block{
    @include tablet{
      position: absolute;
      top: 0.1rem;
      left: 0;
      width: 13.167vw;
      height: 100%;
      background-color: $white;
      z-index: 92;
    }
  }
  & ul{
    display: flex;
    flex-direction: column;
    gap: clamp(5vw, 32px, 10vw);
    padding-left: 0;
    z-index: 91;
    width: 100%;
    margin-top: clamp(16px, 25% , 32px);
    @include smartphone-landscape{
        gap: 1rem;
        margin-top: 5rem;
        margin-left: 7rem;
    }
    @include tablet{
      padding-left: 3rem;
      margin-top: 8rem;      
      gap: 2vw;
    }
    & li{
      font-size: clamp(25px, 4.5vw, 80px);
      text-transform: uppercase;
      color: $black;
      @include smartphone-landscape{
        font-size: clamp(16px, 3.5vw, 24px);
      }
      & a{
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      }
      & span{
      border-radius: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 10vw;
      height: 10vw;
      aspect-ratio: 1/1;
      transform: translate(-1500%);
      text-align: center;
      @include smartphone-landscape{
        width: auto;
        height: auto;
      }
      @include tablet{
        width: 6vw;
        height: 6vw;
      }
      }
      &:hover span{
        background: $black;
        color: $white;
      }
    }
  }
}

.logo{
  position: fixed;
  mix-blend-mode: exclusion;
  width: 30.406vw;
  max-width: 130px;
  z-index: 100;
  @include tablet{
    width: 16.406vw;
    max-width: 420px;
  }
  & svg{
    width: 100%;
    height: 100%;
    fill: #f7f7f7;
  }
}

.burger{
  position: fixed;
  right: 3vw;
  top: 1rem;
  display:flex;
  justify-content:center;
  align-items:center;
  width: calc(var(--header-height) / 2);
  aspect-ratio: 1/1;
  background-color: #f7f7f7;
  border-radius: 100px ;
  mix-blend-mode: exclusion;
  cursor: pointer;
  z-index: 100;
  @include tablet{
    top: 1.5rem ;
  }
  &:hover{
    background-color: $black;
    & svg{
      fill: $white;
    }
  }
  & svg{
    width: 40%;
    height: auto;
  }
}

.top-bar{
  position: absolute;
  top: 15%;
  left: 4%;
  display:flex;
  align-items:center;
  gap: 5%;
  width: 80%;
  max-width: 350px;
  color: $black;
  font-size: clamp(18px,2vw,25px);
  opacity: 0;
  visibility: hidden;
  @include smartphone-landscape{
    right: 1rem;
    left: initial;
    top: 70%;
    flex-flow: column;
    align-items: flex-start;
    width: auto;
    gap: 1rem;
  }
  @include tablet{
    top: 3.5rem;
    left: initial;
    right: 15%;
    width: 25%;
    justify-content: space-between;
  }
  & .lang{
    white-space: nowrap;
    cursor: pointer;
  }
  & .mode{
    display: flex;
    align-items: center;
  }
  & .toggle-btn{
    margin-right: 1rem;
  }
}

</style>
